

.input-area {
  width: 10rem;
}


.input-radio {
  width: 4rem !important;
  background-color: rgba(255, 255, 255, 0.16);
  font-size: 16px;
  height: 1.5rem !important;
  /*display: none; /* Defina display como "none" para tornar o elemento invisível */
}

.label {
  margin-top: 22px;
  margin-bottom: 6px;
  font-size: 1rem;
  color: white;
}

.label1 {
  font-size: 1.5rem;
  color: white;
}

.label input{
  width: 400px !important;
  background-color: rgba(255, 255, 255, 0.16);
  font-size: 16px;
  height: 40px !important
}

.input-card{
  width: 100px !important;
  background-color: rgba(255, 255, 255, 0.16);
  font-size: 16px;
  height: 40px !important
}


.form {
  background-color: #4A3A5C;

}


.div-input input {
  width: 26rem !important;
  margin-left: auto;
  margin-right: auto;
  background-color: #4A3A5C;
  border-radius: 10;
  border-color: #C8C4CE;
  color: #FFFFFF;
}

.div-radius {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-choose-plano {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 0.5rem;
  width: 70rem;
  margin-top: 1rem;
  justify-content: space-between;
}

.div-choose-plano1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 0.5rem;
  width: 70rem;
  margin-top: 1rem;
  justify-content: space-between;
}



.div-radio-plan {
  display: flex;
  align-items: center;
}


.div-h1-title {
color: #FFF;
font-size: 2.5rem;
display: flex;
justify-content: center;
}

.div-h2-sub {
color: #FFF;
font-size: 1.8rem;
background-color: #13072B;
border-radius: 1rem;
padding-left: 2rem;
}

.div-p {
color: #FFF;
padding-left: 2rem;
}

.div-input-paymentsinfo {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.toggle-image-button {
  background-color: #13072B;
  color: white;
  padding: 2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  font-size: 1rem;
}

.div-value-plan {
  display: flex;
  justify-content: center;
  background-color: #13072B;
  margin-left: 1rem;
  border-radius: 1rem;
  height: 8rem;
}

/* .toggle-button {
  background-color: #13072B;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
} */

/* Estilos para botões não selecionados */
.toggle-button {
  background-color: rgba(255, 255, 255, 0.2); /* Cor com 50% de transparência */
  border: 0.2rem solid #13072B;
  border-radius: 1rem;
  padding: 10px;
  cursor: pointer;
}

/* Estilos para botões selecionados */
.toggle-button.selected {
  background-color: #13072B;
  border-radius: 1rem;
  border: 0.2rem solid #FFF;
  color: #fff;
}

.img-channels {
  width: 55rem;
}

@media (min-width: 200px) and (max-width: 299px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input input {
    width: 12rem !important;;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input input {
    width: 16rem !important;;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input input {
    width: 18rem !important;;
  }
}

@media (min-width: 700px) and (max-width: 799px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input input {
    width: 38rem !important;;
  }
}

@media (min-width: 800px) and (max-width: 899px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input input {
    width: 40rem !important;;
  }
}

@media (min-width: 900px) and (max-width: 999px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input input {
    width: 45rem !important;;
  }
}



.valido {
  background-color: #c2f0c2; /* cor verde */
}

.invalido {
  background-color: #f0c2c2; /* cor vermelha */
}

.warning-message {
  color: red;
  font-size: 14px;
}

.input::placeholder {
  color: #C8C3CE;
  font-size: small;
}




.input-cvv::placeholder {
  color: #C8C3CE;
  font-size: small;
}


.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  
}

.button-container button {
  margin: 0 10px;
  width: 200px;
  color: #13072B;
  background-color: #1DEC91;
}


.div-input-plano {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: center;

}


.input-plano {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.input-plano1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}



@media (min-width: 300px) and (max-width: 399px) {
  /* estilo para quando a largura da tela for menor que 400px */

  .div-input-plano {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .div-choose-plano {
    width: 9rem;
    margin-left: -0rem;
    margin-right: -0.5rem;
  }
  .div-input-plano {
    width: 19rem; 
  }
  .button-container button{
    width: 8rem;
  }
}


@media (min-width: 400px) and (max-width: 499px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .div-input-plano {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .div-choose-plano {
    width: 8.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .div-input-plano {
    width: 22rem; 
  }
  .button-container button{
    width: 8rem;
  }
}

@media (min-width: 700px) and (max-width: 799px) {
  /* estilo para quando a largura da tela for menor que 400px */
  .input-plano {
    display: flex;
    align-items: center;
  };
  .div-input-plano {
    display: flex;
    width: 10rem;
  };
  .button-container button {
    width: 10rem !important;
  }
}



.input-container {
  display: inline-flex;
  flex-direction: column;
}input-row

.div-card { 
  display: flex;
  justify-content: space-between;
}

.input-area-cvv {
  display: flex;
}


.div-vigencia { 
  display: flex;
  justify-content: space-between;
}

.div-payment {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}

/* BOTÕES - AVANÇAR, VOLTAR E CONFIRMAR PAGAMENTO */


/* BOTÕES - MÉTODO DE PAGAMENTO */

.div-payment button {
  width: 10rem;
  height: 4.5rem;
  color: white;
  background-color: #13072B; 
}

/* INPUT - VALIDADE E CVV*/

.div-validity { 
  display: flex;
  justify-content: space-between;
}

.div-validity input::placeholder {
  color: #C8C3CE;
  font-size: small;
}

.div-validity input { 
  width: 7rem !important;
  margin-left: auto;
  margin-right: auto;
  background-color: #4A3A5C;
  border-radius: 10;
  border-color: #C8C4CE;
}

/* VERIFICAR */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #4A3A5C inset !important;
  -webkit-text-fill-color: #fff !important;
}


.div-loading-pagamento {
  display: flex;
  position: fixed;
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  justify-content: center;
  align-items: center;
  z-index: 99999;
  flex-direction: column;
}

.div-pagamento {
padding-top: 2em;
  }

.div-p-pagamento {
  color: #FFF;
  font-size: 1.5em;
  }